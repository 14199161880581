import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

const ShoyaBorealis = () => {
  return (
    <div className="container">
      <Helmet>
        <title> The Shoyaright! </title>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta
          name="twitter:title"
          content="Shoya Borealis; or, The Bombing of Shoyastan"
        />
        <meta
          name="twitter:image"
          content="http://shoya.co/static/shoyaright_icon_twitter_card_large_summary-ce9c728db92ba633cc195662666f7a8f.png"
        />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
      </Helmet>
      <link rel="stylesheet" href="../styles/global.css" />
      <div className="article">
        <Link to="/shoyaright">
          <div className="shoyarightChomskyArticle" />
        </Link>
        <div className="articleTitle">
          <p>
            Shoya Borealis; or, <br /> The Bombing of Shoyastan
          </p>
        </div>
        <div className="byLine">
          <p> By: Lil Shoyaright! (Pub. Dec. 27, 2021) </p>
        </div>
        <div className="articleBody">
          <p>
            Inhale deeply through your nose, down into your belly. <br /> Exhale
            sharply through your mouth. <br /> You're alert now.
          </p>
          <p>
            After learning of the{" "}
            <a
              href="http://shoya.co/shoyaright/vol1/mt-shoya/"
              target="_blank"
              rel="noopener noreferrer"
            >
              events at Mt. Shoya
            </a>
            , some people expressed wonder at how I got to be{" "}
            <a
              href="http://shoya.co/about/"
              target="_blank"
              rel="noopener noreferrer"
            >
              who
            </a>{" "}
            and{" "}
            <a
              href="https://twitter.com/zuffshoya/status/1251758025343291392?s=20"
              target="_blank"
              rel="noopener noreferrer"
            >
              what
            </a>{" "}
            I am in the first place.
          </p>
          <p>
            It's simple, really:{" "}
            <span className="editorsNote">
              I bathed in the light of the Borealis and became a trillionaire
            </span>
            .
          </p>
          <p>
            And now, another tale in which I triumph over an existential threat
            to moneykind.
          </p>
          <p className="highlight">Part I: The 7th Shoyaright</p>
          <p>
            A monk shrieks. A nun gasps. A nomad laughs. The stone in the door,
            the hearth, the pendant that they were told would glow bright some
            day now glows. Why?
          </p>
          <p>
            In a green maternity ward, a baby wails as it’s born. It continues
            to cry as it’s placed in a crib. In a purple maternity ward, a baby
            stays perfectly silent as it’s born. It remains calm as it’s placed
            in a crib. Oldie appears to be in both wards, he studies both
            infants. One of em is me, of course, but I don’t know which.
          </p>
          <p>
            Many years later, me and the rest of the Shoyarights arrive back at
            HQ, all six dressed in our fatigues. It had been a long day: we had
            brokered a deal to install a junta in [REDACTED], and then went to
            see a play after.
          </p>
          <p>BIN SHOYA: Many Jews, no?</p>
          <p>
            The rest of us groan and suck our teeth. We find Oldie seated at the
            massive oak-slab table. A stranger sits beside him. Oldie watches me
            carefully.
          </p>
          <p>OLDIE: Someone you should meet.</p>
          <p>
            The stranger rises. We examine each other. Reader, it’s you! Yes,
            you! A green and purple aura surrounds us as we meet for the first
            time. You had been that other baby. Which one?
          </p>
          <p>
            In the months that follow, we all take a liking to you. You become
            one of us. We shoot movies, record music, play sports, and of
            course, overthrow hostile governments and corporations alike. I
            should be more suspicious of you, but there’s a mysterious force
            keeping us in harmony. This would prove to be the first mistake of
            my entire life.
          </p>
          <p>
            One day, as we all meet to discuss our approach to an ongoing
            mission, you make a comment.
          </p>
          <p>
            YOU: Our objective should be to give the greatest number of people
            the highest quality of life possible.
          </p>
          <p>
            An awkward silence takes hold of the room. Then, it erupts with
            laughter. The two of us lock eyes. You pretend to laugh along, but I
            know that I’m seeing your true face for the first time.
          </p>
          <p>I visit Oldie in his glassy office. I’m in a quiet rage.</p>
          <p>ME: Why did you bring me a Judas?</p>
          <p>
            Oldie calmly reaches for a steel dossier, places it on his desk and
            opens it for me to see. It contains a pie chart that shows the major
            shareholders of Shoya, Inc. I know this chart in my blood, why show
            me this now? He points to one of the larger slices of the pie.
            Unlike the others, this slice has always been labeled with a
            question mark — my brain ignores it the way it ignores my nose. I
            was told this entity would only be revealed when....
          </p>
          <p>
            My eyes widen! Could <i className="editorsNote">you</i> really
            be...?!
          </p>
          <p>Before I could act, you vanished from our lives completely.</p>
          <p className="highlight">Part II: The Bombing of Shoyastan</p>
          <p>
            It’s years later now. The morning of January 20th, 2010, to be
            exact. I’m in my business suite, surrounded by a dozen
            political-looking, lanyard-wearing cronies in suits. We’re gathered
            around a large television, many of them are running around typing
            furiously on their BlackBerrys or taking calls. I’m seated, fully
            fixated on the television screen.
          </p>
          <p>
            I get a call on my iPhone 21. I snap my fingers and the room falls
            silent. Everyone’s eyes are on me as I take the call. I listen
            carefully for a moment. Then I crack a grin, and give a thumbs up.
            Everyone explodes into cheers.
          </p>
          <p>
            We all make our way hastily onto the rooftop helipads, where four
            choppers already have their blades spinning. We all board, and put
            on our headsets as we prepare for takeoff.
          </p>
          <p>
            It’s the eve of the{" "}
            <a
              href="https://en.wikipedia.org/wiki/Citizens_United_v._FEC"
              target="_blank"
              rel="noopener noreferrer"
            >
              Citizens United
            </a>{" "}
            decision, hence all the commotion. A legal curse had prevented me
            from setting foot on US soil for 8 years, and I had just gotten word
            that the curse would soon be lifted by the US Supreme Court. I was
            dying to smell that Wall St. air again.
          </p>
          <p>
            Our choppers land at the clearport. As we walk across the airstrip
            toward our jet, I get another call. The caller is “UNKNOWN”.
          </p>
          <p>
            ME: Hello? <br /> YOU: It’s me.
          </p>
          <p>I'm stunned.</p>
          <p>YOU: I can’t let you go back.</p>
          <p>
            Just then, someone points out a fleet of{" "}
            <a
              href="https://en.wikipedia.org/wiki/Multirole_combat_aircraft"
              target="_blank"
              rel="noopener noreferrer"
            >
              MRCA
            </a>
            ’s approaching on the horizon.
          </p>
          <p>
            ME: Why are you doing this? Who <i>are</i> you? <br />
            YOU:{" "}
            <span className="editorsNote">
              I ate of the flesh of the mushroom and became the People’s
              Champion
            </span>
            .
          </p>
          <p>
            There’s a ringing in my ears. Everything's moving in slow motion. I
            drop my iPhone 21. The enemy jets drop their payloads, the bombs
            fall silently before: BOOM BOOM BOOM BOOM BOOM AHHH YO WTF!!!!!!!
          </p>
          <p>
            I board our jet, and we rush to take off. The enemy fleet ceases
            bombing and opens fire on us directly. I enter the cockpit.
          </p>
          <p>
            ME: Turn us around. <br />
            PILOT: But sir, we can escape! <br />
            ME: FUCK THAT!!!
          </p>
          <p>
            I snatch the pilot from his chair and take his place. I pull a quick
            U-turn and then press some buttons that make all these guns pop out
            on the jet omg lmaooo then I go DOOT DOOT DOOT DOOT and down like 4
            of the 5 enemy planes off rip. Now it’s just me and the last jet
            headed right at each other. I don’t blink or break a sweat. The
            other pilot realizes it’s too late to bail now. At the last second,
            I hit the eject button and the two jets collide causing an explosion
            that disperses the clouds around us. My parachute deploys
            successfully.
          </p>
          <p>
            Days later, you’re at your base camp on the side of a snowy
            mountain. You receive a phone call. You remove your glove to answer.
          </p>
          <p>GOLDIE: You have a problem.</p>
          <p>
            A bright yellow cab pulls over on a bustling city street. The rear
            door opens, and a Nike boot makes contact with the concrete. I exit,
            stand tall, and take a loooong sniff.
          </p>
          <p className="highlight">Appendix</p>
          <p>
            You may know of a disputed territory in Asia that’s bordered by
            three nuclear-capable nations. In reality, this area at the
            intersection of Pakistan, India, and China, known widely as Kashmir,
            isn’t disputed whatsoever. It’s known to those with high enough
            security clearance in their respective jurisdictions by it’s true
            name: Shoyastan.
          </p>
          <p>
            The “dispute” is carefully maintained through mainstream propaganda
            so as to prevent the general public from learning the truth, that
            Shoyastan contains the highest density, largest volume, and widest
            variety of rare metals and minerals on the face of the Earth. The
            mountains are mostly diamonds, the rivers are mostly gold, and the
            sands are mostly lithium. Most importantly, it contains the rarest
            and most precious element of all, one that can only be found within
            its borders: Rightium.
          </p>
        </div>
        <p className="plug">
          If you enjoyed this article, consider{" "}
          <a href="https://www.patreon.com/join/shoyaright?">
            becoming a patron
          </a>{" "}
          or making a{" "}
          <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=zuff@shoya.co&lc=US&item_name=The%20Shoyaright%21&currency_code=USD&no_note=0&bn=PP-DonationsBF:btn_donateCC_LG.gif:NonHostedGuest">
            one-time pledge
          </a>
          . Support corporate journalism!
        </p>
        <div className="articleCopyright">
          <p>&copy; Shoya, Inc. 2021</p>
        </div>
      </div>
    </div>
  )
}
export default ShoyaBorealis
